<template>
  <el-dialog
    title="Cập nhật phòng ban"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      label-width="120px"
      :model="editDepartment"
      label-position="left"
      :rules="rules"
      ref="editDepartment"
    >
      <el-form-item label="Tên phòng ban" prop="departmentName">
        <el-input
          v-model="editDepartment.departmentName"
          placeholder="Nhập tên phòng ban"
        ></el-input>
        <span class="error-message">{{ customErrorMessages.newNameKt }}</span>
      </el-form-item>
      <el-form-item label="Mô tả" prop="departmentDescription">
        <el-input
          :rows="1"
          type="textarea"
          autosize
          v-model="editDepartment.departmentDescription"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button
            type="success"
            size="medium"
            :loading="loadingButton"
            v-if="showSaveChange"
            mini
            @click="submitForm('editDepartment')"
        >
        <i class="el-icon-circle-check" />
        <span>Lưu</span>
      </el-button>
      <el-button
        type="danger"
        size="medium"
        @click="closeDialogByButton('editDepartment')"
      >
        <i class="el-icon-circle-close" />
        <span>Đóng</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import DepartmentDataService from "@/services/EmployeeService/DepartmentDataService";
import {mapActions, mapGetters} from "vuex";
export default {
  props: {
    dialogVisible: null,
    editDepartment: null,
  },
  data() {
    return {
      loadingButton: false,
      customErrorMessages: {
        newNameKt: ""
      },
      showSaveChange: false,
      rules: {
        departmentName: [
          {
            validator: this.changeDepartmentName,
            trigger: "change"
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('departmentStore', ['departmentList']),
  },
  methods: {
    ...mapActions('departmentStore',['fetchDataDepartmentList']),
    //reset when click x
    closeDialog() {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      this.$refs["editDepartment"].resetFields();
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },

    changeDepartmentName(rule, value, callback){
      for (let i = 0; i < this.departmentList.length ; i++) {
        if (!value) {// Kiểm tra nếu giá trị rỗng
          this.customErrorMessages.newNameKt = "Giá trị không được để trống!";
          this.showSaveChange = false
          break
        }else  if (value.toLowerCase().replace(/\s/g, "") === this.departmentList[i].departmentName.toLowerCase().replace(/\s/g, "")){
          this.customErrorMessages.newNameKt = "Tên phòng ban: "+this.departmentList[i].departmentName + " đã tồn tại!";
          this.showSaveChange = false
          break
        }else {
          this.showSaveChange = true
          this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
          callback();
        }
      }
    },

    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = false;
          DepartmentDataService.update(this.editDepartment.id, this.editDepartment)
            .then((resp) => {
              this.fetchDataDepartmentList();
              this.$message({
                message: resp.data.message,
                type: "success",
              });
                this.closeDialogByButton(fromData);
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            })
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner,
/deep/.el-textarea__inner {
  border: none;
  border-radius: 0;
  resize: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #d9d9d9;
}
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

.error-message {
  color: red;
}
</style>
